<template>
<div class="mbanner" >
	<div class="wrapper">
		<div class="mbanner-hd">
			<div class="cn"><h2>⼯作站</h2></div>
			<div class="en">Introduction to Workstation</div>
		</div>
		<div class="mbanner-bd mbanner-bd1">
			人力资源服务工作站是集区域政府公共服务职能和产业园入驻机构市场化服务于一体的人力资源和人才服务综合平台。<br />
充分利用产业功能区集聚特点，实现人力资源服务产业园服务触角延伸，将政府职能部门、<br />
区域、产业、企业、人才在发展过程中对人力资源的需求深度整合和协同，<br />
“促就业、促人才配置、促经济发展”为产业协同发展提供服务，<br />
实现以人力资源推动实体经济转型发展的目标。
		</div>
	</div>
</div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style scoped lang="scss">
.mbanner {
  background-image: url(../../../assets/images/mbanner07.jpg);
}
</style>
