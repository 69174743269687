<template>
  <div class="gzz">
    <div class="wrapper">
      <div class="gzz-list">
        <ul>
          <li class="l1"
              v-for="item in list"
              :key="item.id"
              :style="{ backgroundImage: 'url(' + item.picture + ')' }">
            <h3 class="tit">{{ item.name }}</h3>
            <div class="txt"
                 v-html="item.introduce">
            </div>
            <div class="btn">
              <a @click="toDetail('article', item)"
                 class="mbtn ful orange">进入工作站</a>
            </div>
          </li>
        </ul>
      </div>
      <!-- <div class="gzz-btn">
        <a @click="toRouter('apply',{cn:'在线咨询',en:'Online consultation',type:3})"
           class="mfullbtn"><img src="../../../assets/images/icon33.png" />在线咨询</a>
      </div> -->
    </div>
  </div>
</template>
<script>
import { newRouter } from "@/libs/comm";
export default {
  data () {
    return {
      list: [],
    };
  },
  mounted () {
    this.getWorkstationList();
  },
  methods: {
    toRouter (routerName, item) {
      let query = {
        ...item
      };
      this.newRouter('/' + routerName, query)
    },
    toDetail (path, data) {
      newRouter("/" + path, {
        id: data.articleId,
        name: data.name,
        applyId: data.id,
        toApply: true,
      });
    },
    getWorkstationList () {
      this.api.getWorkstationList({ pageNo: 1, pageSize: 50 }).then((res) => {
        const {
          data: { result },
        } = res;
        const { records } = result || [];
        this.list = records;
      });
    },
  },
};
</script>
<style scoped lang="scss">
@media screen and (max-width: 1201px) {
    li {
      height: 192px;
      position: relative;
      padding: 23px 30px 0 30px;
    }
    .btn{
      position: absolute;
      bottom: 31px;
    }
}
</style>
